import React, { Component } from 'react';
import MapPicker from 'react-google-map-picker';
import axios from 'axios'; // Install axios if not already installed
import { GOOGLEMAPS_API } from '../../projectString';

export default class LocationPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      zoom: 10,
      latitude: sessionStorage.getItem('latitude') ? parseFloat(sessionStorage.getItem('latitude')) : 25.1988,
      longitude: sessionStorage.getItem('longitude') ? parseFloat(sessionStorage.getItem('longitude')) : 55.2796,
      address: '', // Store the address
    };
  }

  // Fetch place name and address using reverse geocoding
  getPlaceDetails = (latitude, longitude) => {
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLEMAPS_API}`;
    axios
      .get(geocodeUrl)
      .then((response) => {
        if (response.data.status === 'OK' && response.data.results.length > 0) {
          const address = response.data.results[0].formatted_address;
          this.setState({ address });

          // Pass latitude, longitude, and address to parent component
          this.props.changeLatLng(latitude, longitude, address);
        } else {
          console.error('No results found for reverse geocoding');
        }
      })
      .catch((error) => {
        console.error('Error fetching geocode data:', error);
      });
  };

  handleChangeLocation = (latitude, longitude) => {
    this.setState({
      latitude,
      longitude,
    });

    // Fetch the place details (address) and notify parent
    this.getPlaceDetails(latitude, longitude);
  };

  handleChangeZoom = (newZoom) => {
    this.setState({
      zoom: newZoom,
    });
  };

  render() {
    const { zoom, latitude, longitude, address } = this.state;

    let defaultLocation = { lat: latitude, lng: longitude };

    return (
      <>
        <div className="create-ad-location mb-4 mb-md-5">
          <h4 className="title mb-2">Is the pin in the right location?</h4>
          <p>
            Click and drag the pin to the exact spot. Users are more likely to respond to ads that
            are correctly shown on the map
          </p>
          <MapPicker
            className="map-frame overflow-hidden"
            defaultLocation={defaultLocation}
            zoom={zoom}
            mapTypeId="roadmap"
            style={{ height: '300px' }}
            onChangeLocation={this.handleChangeLocation}
            onChangeZoom={this.handleChangeZoom}
            apiKey={GOOGLEMAPS_API}
          />
          {/* <div className="mt-3">
            <h5>Selected Location:</h5>
            <p><strong>Latitude:</strong> {latitude}</p>
            <p><strong>Longitude:</strong> {longitude}</p>
            <p><strong>Address:</strong> {address || 'Fetching address...'}</p>
          </div> */}
        </div>
      </>
    );
  }
}
